<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
      	
      	<v-card class="shadowCard">
      	  <v-card-title class="text-subtitle-1">
      	    Rol de teachers por sucursal
      	    <v-spacer></v-spacer>

      	    <!-- Botón par aexportar -->
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()"
              tile
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

      	  	<!-- Botón para consultar la iformación al sistema -->
      	    <v-btn 
              small dark color="orange"
              @click="consultar()"
              tile
            >
              Consultar
            </v-btn>

      	  </v-card-title>

      	  <!-- Tabla y filtros -->
      	  <v-card-text>

      	  	<!-- Filtro de ciclos -->
      	    <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <!-- Mostrar información por bloques -->
            <v-row justify="space-between">
            	<v-col cols="12" md="6" lg="4">

            		<v-select
            		  :items="[{ bloque: 'Semana 1', value: 1},{ bloque: 'Semana 2', value: 2},{ bloque: 'Semana 3', value: 3},{ bloque: 'Semana 4', value: 4}]"
            		  v-model="selectedHeaders"
            		  label="SEMANA"
            		  filled
            		  hide-details
            		  item-text="bloque"
                  item-value="value"
            		></v-select>

            		<!-- <v-radio-group
						      v-model="selectedHeaders"
						      row
						      hide-details
						    >
						      <v-radio
						        label="Semana 1"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="Semana 2"
						        :value="2"
						      ></v-radio>
						      <v-radio
						        label="Semana 3"
						        :value="3"
						      ></v-radio>
						      <v-radio
						        label="Semana 4"
						        :value="4"
						      ></v-radio>
						    </v-radio-group> -->
            	</v-col>

              <v-col cols="12" md="6" lg="6">
                <v-text-field
                  v-model="search"
                  label="Búscar"
                  dense
                  filled
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>


            <!-- Check para los eliminados y optimizados -->
            <v-row>
            	<v-col cols="12" md="3" lg="2">
            		<v-checkbox
						      v-model="checkEliminados"
						      label="Eliminados"
						    ></v-checkbox>
            	</v-col>

            	<!-- Sleleccionar un curso -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="[{id: 1, texto: 'Dominical'},{id: 2, texto: 'Sabatino'},{id: 3, texto: 'Entre semana'}]"
						      v-model="curso"
						      item-text="texto"
						      item-value="id"
						      label="Frecuencia"
						      clearable
						      dense
						    ></v-autocomplete>
            	</v-col>

            	<!-- Seleccionar la modalidad -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="[{id: 1, texto: 'Online'},{id: 0, texto: 'Presencial'}]"
						      v-model="modalidad"
						      item-text="texto"
						      item-value="id"
						      label="Modalidad"
						      clearable
						      dense
						    ></v-autocomplete>
            	</v-col>

            	<!-- Seleccionar un nivel -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="niveles"
						      v-model="nivel"
						      label="Nivel"
						      clearable
						      dense
						    ></v-autocomplete>
            	</v-col>

            	<!-- Seleccionar un plantel -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="planteles"
						      v-model="plantel"
						      label="Plantel"
						      clearable
						      dense
						      item-text="plantel"
						      item-value="plantel"
						    ></v-autocomplete>
            	</v-col>

            	<!-- Seleccionar un horario -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="horarios"
						      v-model="horario"
						      label="Horario"
						      clearable
						      dense
						    ></v-autocomplete>
            	</v-col>

            	<!-- Filtrar por teacher -->
            	<v-col cols="12" md="3" lg="4">
						    <v-autocomplete
						    	filled
						      :items="teachersFiltros"
						      v-model="teacherFilter"
						      label="Teacher"
						      clearable
						      dense
						    ></v-autocomplete>
            	</v-col>

            	<!-- Filtrar por teacher -->
            	<v-col cols="12" md="3" lg="4">
						    <h3> TOTAL DE GRUPOS: {{ filterGrupos.length }} </h3>
						    <h3> Asignados: {{ gruposCompletos.length }} </h3>
						    <h3> FALTANTES: {{ gruposFaltantes.length }} </h3>
						    <h3> SIN BITACORA: {{ filterSinBitacora }} </h3>
            	</v-col>

            	<!-- Filtrar por teacher -->
            	<v-col cols="12" md="3" lg="4">
						    <h3> HORAS POR SEMANA: {{ horasSemana }} </h3>
						    <h3> HORAS POR CATORCENA: {{ horasCatorcena }} </h3>
						    <h3> HORAS POR CICLO: {{ horasCiclo }} </h3>
            	</v-col>

            </v-row>
		        
		        <!-- Tabla con toda la información -->
		        <v-data-table
		          :headers="headers"
		          :items="filterGrupos"
		          class="elevation-0"
		          height="500"
		          dense
		          fixed-header
		          :search="search"
		          multi-sort
		          :loading="cargar"
		        > 
		        	<template v-slot:item.grupo="{ item }">
		        		<span v-if="item.deleted == 0" @click="codigo = item, dialogZoom = true" class="subrayado"> {{ item.grupo }} </span>
		        		<span v-else class="red--text subrayado" @click="codigo = item, dialogZoom = true" > {{ item.grupo }} </span>
		          </template>

		          <template v-slot:item.salon="props">
				        <v-edit-dialog
				          :return-value.sync="props.item.salon_2"
				          @save="saveSalon( props.item )"
				          large
				        >
				          {{ props.item.salon_2 ? props.item.salon_2 : 'SIN SALÓN' }}
				          <template v-slot:input>
				            <v-autocomplete
		                  :items="salones"
		                  v-model="props.item.id_salon_2"
		                  label="Salone"
		                  dense
		                  filled
		                  item-value="id_salon"
		                  item-text="salon"
		                  class="ma-2"
		                  hide-details
		                ></v-autocomplete>
				          </template>
				        </v-edit-dialog>
				      </template>

		          <template v-slot:item.optimizado="{ item }">
		            <v-chip v-if="item.optimizado == 1" small rounded color="green" dark >Optimizado</v-chip>
		          </template>

		          <template v-slot:item.modalidad="{ item }">
		            <v-chip v-if="item.modalidad == 1" small rounded color="green"  dark >Online</v-chip>
		            <v-chip v-if="item.modalidad == 0" small rounded color="orange" dark >Presencial</v-chip>
		          </template>

		          <template v-slot:item.id_unidad_negocio="{ item }">
		            <v-chip v-if="item.id_unidad_negocio == 1" small rounded color="blue lighten-2" dark >INBI</v-chip>
		            <v-chip v-else small rounded color="blue darken-2" dark >FAST</v-chip>
		          </template>

		          <template v-slot:item.plantel="{ item }">
                <span class="subrayado" @click="codigo = item, dialogMaps.estatus = true">{{ item.plantel }}</span>
              </template>

		          <!-- Editar Bloque 1 -->
		          <template v-slot:item.lu1_n="{ item }">
		            <span 
		            	v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'lu1', grupo = item.iderp, asistencia = item.lu1, getPosiblesMaestros( item.lu1_dia , item, item.lu1_bloqueo), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.lu1_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.lu1_fecha <= fecha_hoy && !item.lu1_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.lu1_confirmacion == 1 ? 'green' :  item.lu1_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.lu1_n }}
		            	<br/>
		            	<v-chip color="green" dark small v-if="item.lu1_as && item.lu1_as.substr( item.lu1_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.lu1_as && item.lu1_as.substr( item.lu1_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.lu1_as }}</span>
							    </v-tooltip>
							    <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.llu1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		            </span>
		          </template>

		          <template v-slot:item.ma1_n="{ item }">
		            <span 
		            	v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ma1', grupo = item.iderp, asistencia = item.ma1, getPosiblesMaestros( item.ma1_dia , item, item.ma1_bloqueo), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.ma1_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.ma1_fecha <= fecha_hoy && !item.ma1_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.ma1_confirmacion == 1 ? 'green' :  item.ma1_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.ma1_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.ma1_as && item.ma1_as.substr( item.ma1_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.ma1_as && item.ma1_as.substr( item.ma1_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.ma1_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lma1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.mi1_n="{ item }">
		            <span 
		            	v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'mi1', grupo = item.iderp, asistencia = item.mi1, getPosiblesMaestros( item.mi1_dia , item, item.mi1_bloqueo), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.mi1_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.mi1_fecha <= fecha_hoy && !item.mi1_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.mi1_confirmacion == 1 ? 'green' :  item.mi1_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.mi1_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.mi1_as && item.mi1_as.substr( item.mi1_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.mi1_as && item.mi1_as.substr( item.mi1_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.mi1_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lmi1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ju1_n="{ item }">
		            <span 
		            	v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ju1', grupo = item.iderp, asistencia = item.ju1, getPosiblesMaestros( item.ju1_dia , item, item.ju1_bloqueo), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.ju1_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.ju1_fecha <= fecha_hoy && !item.ju1_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.ju1_confirmacion == 1 ? 'green' :  item.ju1_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.ju1_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.ju1_as && item.ju1_as.substr( item.ju1_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.ju1_as && item.ju1_as.substr( item.ju1_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.ju1_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lju1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.vi1_n="{ item }">
		            <span 
		            	v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" 
		            	type="button" 
		            	@click="bloque =  'vi1', grupo = item.iderp, asistencia = item.vi1, getPosiblesMaestros( item.vi1_dia , item, item.vi1_bloqueo), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.vi1_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.vi1_fecha <= fecha_hoy && !item.vi1_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.vi1_confirmacion == 1 ? 'green' :  item.vi1_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.vi1_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.vi1_as && item.vi1_as.substr( item.vi1_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.vi1_as && item.vi1_as.substr( item.vi1_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.vi1_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lvi1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>


		          <!-- Editar Bloque 2 -->
		          <template v-slot:item.lu2_n="{ item }">
		            <span 
		            	v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'lu2', grupo = item.iderp, asistencia = item.lu2, getPosiblesMaestros( item.lu2_dia , item, item.lu2_bloqueo), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.lu2_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.lu2_fecha <= fecha_hoy && !item.lu2_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.lu2_confirmacion == 1 ? 'green' :  item.lu2_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.lu2_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.lu2_as && item.lu2_as.substr( item.lu2_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.lu2_as && item.lu2_as.substr( item.lu2_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.lu2_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.llu2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ma2_n="{ item }">
		            <span 
		            	v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ma2', grupo = item.iderp, asistencia = item.ma2, getPosiblesMaestros( item.ma2_dia , item, item.ma2_bloqueo), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.ma2_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.ma2_fecha <= fecha_hoy && !item.ma2_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.ma2_confirmacion == 1 ? 'green' :  item.ma2_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.ma2_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.ma2_as && item.ma2_as.substr( item.ma2_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.ma2_as && item.ma2_as.substr( item.ma2_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.ma2_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lma2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.mi2_n="{ item }">
		            <span 
		            	v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'mi2', grupo = item.iderp, asistencia = item.mi2, getPosiblesMaestros( item.mi2_dia , item, item.mi2_bloqueo), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.mi2_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.mi2_fecha <= fecha_hoy && !item.mi2_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.mi2_confirmacion == 1 ? 'green' :  item.mi2_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.mi2_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.mi2_as && item.mi2_as.substr( item.mi2_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.mi2_as && item.mi2_as.substr( item.mi2_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.mi2_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lmi2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ju2_n="{ item }">
		            <span 
		            	v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ju2', grupo = item.iderp, asistencia = item.ju2, getPosiblesMaestros( item.ju2_dia , item, item.ju2_bloqueo), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.ju2_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.ju2_fecha <= fecha_hoy && !item.ju2_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.ju2_confirmacion == 1 ? 'green' :  item.ju2_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.ju2_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.ju2_as && item.ju2_as.substr( item.ju2_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.ju2_as && item.ju2_as.substr( item.ju2_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.ju2_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lju2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.vi2_n="{ item }">
		            <span 
		            	v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" 
		            	type="button" 
		            	@click="bloque =  'vi2', grupo = item.iderp, asistencia = item.vi2, getPosiblesMaestros( item.vi2_dia, item, item.vi2_bloqueo ), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.vi2_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.vi2_fecha <= fecha_hoy && !item.vi2_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.vi2_confirmacion == 1 ? 'green' :  item.vi2_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.vi2_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.vi2_as && item.vi2_as.substr( item.vi2_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.vi2_as && item.vi2_as.substr( item.vi2_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.vi2_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lvi2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>


		          <!-- Editar Bloque 3 -->
		          <template v-slot:item.lu3_n="{ item }">
		            <span 
		            	v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'lu3', grupo = item.iderp, asistencia = item.lu3, getPosiblesMaestros( item.lu3_dia, item, item.lu3_bloqueo ), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.lu3_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.lu3_fecha <= fecha_hoy && !item.lu3_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.lu3_confirmacion == 1 ? 'green' :  item.lu3_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.lu3_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.lu3_as && item.lu3_as.substr( item.lu3_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.lu3_as && item.lu3_as.substr( item.lu3_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.lu3_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.llu3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ma3_n="{ item }">
		            <span 
		            	v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ma3', grupo = item.iderp, asistencia = item.ma3, getPosiblesMaestros( item.ma3_dia, item, item.ma3_bloqueo ), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.ma3_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.ma3_fecha <= fecha_hoy && !item.ma3_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.ma3_confirmacion == 1 ? 'green' :  item.ma3_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.ma3_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.ma3_as && item.ma3_as.substr( item.ma3_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.ma3_as && item.ma3_as.substr( item.ma3_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.ma3_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lma3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.mi3_n="{ item }">
		            <span 
		            	v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'mi3', grupo = item.iderp, asistencia = item.mi3, getPosiblesMaestros( item.mi3_dia, item, item.mi3_bloqueo ), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.mi3_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.mi3_fecha <= fecha_hoy && !item.mi3_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.mi3_confirmacion == 1 ? 'green' :  item.mi3_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.mi3_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.mi3_as && item.mi3_as.substr( item.mi3_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.mi3_as && item.mi3_as.substr( item.mi3_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.mi3_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lmi3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ju3_n="{ item }">
		            <span 
		            	v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ju3', grupo = item.iderp, asistencia = item.ju3, getPosiblesMaestros( item.ju3_dia, item, item.ju3_bloqueo ), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.ju3_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.ju3_fecha <= fecha_hoy && !item.ju3_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.ju3_confirmacion == 1 ? 'green' :  item.ju3_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.ju3_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.ju3_as && item.ju3_as.substr( item.ju3_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.ju3_as && item.ju3_as.substr( item.ju3_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.ju3_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lju3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.vi3_n="{ item }">
		            <span 
		            	v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" 
		            	type="button" 
		            	@click="bloque =  'vi3', grupo = item.iderp, asistencia = item.vi3, getPosiblesMaestros( item.vi3_dia, item, item.vi3_bloqueo ), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.vi3_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.vi3_fecha <= fecha_hoy && !item.vi3_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.vi3_confirmacion == 1 ? 'green' :  item.vi3_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.vi3_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.vi3_as && item.vi3_as.substr( item.vi3_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.vi3_as && item.vi3_as.substr( item.vi3_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.vi3_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lvi3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>


		          <!-- Editar Bloque 4 -->
		          <template v-slot:item.lu4_n="{ item }">
		            <span 
		            	v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'lu4', grupo = item.iderp, asistencia = item.lu4, getPosiblesMaestros( item.lu4_dia, item, item.lu4_bloqueo ), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.lu4_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.lu4_fecha <= fecha_hoy && !item.lu4_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.lu4_confirmacion == 1 ? 'green' :  item.lu4_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.lu4_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.lu4_as && item.lu4_as.substr( item.lu4_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.lu4_as && item.lu4_as.substr( item.lu4_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.lu4_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.llu4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ma4_n="{ item }">
		            <span 
		            	v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ma4', grupo = item.iderp, asistencia = item.ma4, getPosiblesMaestros( item.ma4_dia, item, item.ma4_bloqueo ), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.ma4_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.ma4_fecha <= fecha_hoy && !item.ma4_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.ma4_confirmacion == 1 ? 'green' :  item.ma4_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.ma4_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.ma4_as && item.ma4_as.substr( item.ma4_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.ma4_as && item.ma4_as.substr( item.ma4_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.ma4_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lma4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.mi4_n="{ item }">
		            <span 
		            	v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'mi4', grupo = item.iderp, asistencia = item.mi4, getPosiblesMaestros( item.mi4_dia, item, item.mi4_bloqueo ), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.mi4_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.mi4_fecha <= fecha_hoy && !item.mi4_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.mi4_confirmacion == 1 ? 'green' :  item.mi4_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.mi4_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.mi4_as && item.mi4_as.substr( item.mi4_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.mi4_as && item.mi4_as.substr( item.mi4_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.mi4_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lmi4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ju4_n="{ item }">
		            <span 
		            	v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ju4', grupo = item.iderp, asistencia = item.ju4, getPosiblesMaestros( item.ju4_dia, item, item.ju4_bloqueo ), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.ju4_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.ju4_fecha <= fecha_hoy && !item.ju4_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.ju4_confirmacion == 1 ? 'green' :  item.ju4_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.ju4_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.ju4_as && item.ju4_as.substr( item.ju4_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.ju4_as && item.ju4_as.substr( item.ju4_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.ju4_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lju4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.vi4_n="{ item }">
		            <span 
		            	v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" 
		            	type="button" 
		            	@click="bloque =  'vi4', grupo = item.iderp, asistencia = item.vi4, getPosiblesMaestros( item.vi4_dia, item, item.vi4_bloqueo ), idrol_clases = item.idrol_clases, getBitacora()"
		            	:class="item.vi4_falta ? 'red darken-2 white--text' : '' "
		            	:id="!item.grupo.match('ONLINE') && item.vi4_fecha <= fecha_hoy && !item.vi4_hora_asistencia ? 'yellow' : '' "
		            >
		          		<v-icon :color="item.vi4_confirmacion == 1 ? 'green' :  item.vi4_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		          		{{ item.vi4_n }}
		          	</span>
		            <br/>
		            <v-chip color="green" dark small v-if="item.vi4_as && item.vi4_as.substr( item.vi4_as.length - 1,1 ) == 1 ">Asistió</v-chip>
		            <v-tooltip bottom>
						      <template v-slot:activator="{ on, attrs }">
            				<v-chip 
						          v-bind="attrs"
						          v-on="on"
            					color="red" 
            					dark 
            					small 
            					v-if="item.vi4_as && item.vi4_as.substr( item.vi4_as.length - 1,1 ) == 2 "
            				>
            					No Asistió
            				</v-chip>
						      </template>
						      <span>{{ item.vi4_as }}</span>
						    </v-tooltip>
						    <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lvi4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
		          </template>
		        </v-data-table>
      	  </v-card-text>
      	</v-card>

      </v-col>
    </v-row>

    <!-- Dialogo para agregar un maestro al grupo -->
    <v-dialog
      v-model="dialogAsistencia"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Asistencia y Bitacora
        </v-card-title>
        <v-card-text>

        	<div v-if="grupoCompleto && grupoCompleto[`${bloque}_falta`]" class="black--text">

        		<v-alert
				      border="top"
				      dark
				      type="error"
				    >
	        		ALERTAA: TEACHER NO ASISITIRÁ A CLASE
	        		<br/>
	        		{{ grupoCompleto[`${bloque}_falta`].nombre_completo }}
	        		<br/>
	        		{{ grupoCompleto[`${bloque}_falta`].motivo }}
				    </v-alert>

        	</div>
        	
          <v-radio-group
			      v-model="opAsignacion"
			      row
			      hide-details
			    >
			      <v-radio
			        label="Asistió"
			        :value="1"
			      ></v-radio>
			      <v-radio
			        label="No Asistió"
			        :value="2"
			      ></v-radio>
			    </v-radio-group>

			    <br/>
			    <br/>

			    <!-- Si fue inasistencia, hay que poner quien lo cubrió -->
			    <label v-if="opAsignacion == 2">Quién reemplazó?</label>
			    <v-autocomplete
            filled
            dense
            clearable
            v-model="teacherReemplazo"
            :items="teachersPosibles"
            label="Selecciona el teacher"
            item-text="nombre_completo"
            item-value="nombre_completo"
            class="mt-4"
            hide-details
            v-if="opAsignacion == 2"
          >
          </v-autocomplete>

          <div v-if="bitacora">
          	<b>Fecha creación:</b> {{ bitacora.fecha }}
          	<br/> 
          	<b>Teacher:</b> {{ bitacora.teacher }} 
          	<br/> 
          	<b>Enc / Rec:</b> {{ bitacora.usuario }} 

          </div>
          <!-- Aquí van las preguntas de la bitacora -->
          <span >
          	<v-checkbox 
          		v-for="(pregunta, i) in preguntas"
          		:key="i"
          		:label="pregunta.pregunta" 
          		v-model="pregunta.correcto"
          		hide-details
          		small 
          		:readonly="mostrarBitacora"
          	></v-checkbox>
          </span>

        </v-card-text>
        <v-card-actions>
        	<v-btn 
        		color="black"
        		small 
        		rounded 
        		@click="dialogAsistencia = false, teacherReemplazo = null" 
        		dark
        		tile
        	>
        		<v-icon small left>mdi-cancel</v-icon>
        		Cancelar
        	</v-btn>

          <v-spacer></v-spacer>

        	<v-btn 
        		color="green" 
        		dark
        		small 
        		rounded 
        		@click="guardarAsistencia( )"
        		tile
        	>
        		<v-icon small left>mdi-content-save</v-icon>
        		Guardar
        	</v-btn>
        
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogZoom"
      max-width="400"
      v-if="codigo"
    >
      <v-card>
        <v-card-title class="text-h5">
          Codigo Clase
        </v-card-title>
        <v-card-text>
	  			<v-textarea
	  				v-model="codigo.codigos"
	  				rows="3"
	  				auto-grow
	  				label="Agregar acceso al zoom"
	  				filled
	  				hide-details
	  				readonly
	  			/>
        </v-card-text>
        <v-card-actions>
        	<v-btn
            color="black"
            tile
            dark
            small
            @click="dialogZoom = false"
          >
          	<v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>

          <v-btn
            color="orange"
            tile
            dark
            small
            @click="copiarPortapapeles( codigo.codigos )"
          >
          	<v-icon small left>mdi-content-copy</v-icon>
            Copiar
          </v-btn>

          <v-btn
            color="green"
            tile
            dark
            small
            @click="abrirZoom( codigo.codigos )"
          >
          	<v-icon small left>mdi-lock-open</v-icon>
            Abrir Zoom
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialogMaps
      v-if="dialogMaps.estatus"
      :dialogMaps="dialogMaps"
      :codigo="codigo"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  
  import dialogMaps            from '@/components/roles/dialogMaps.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import filtrosRolSucursal    from '@/mixins/filtrosRolSucursal'

  export default {

  	components:{
      Alerta,
      carga,
      dialogMaps,
    },

    mixins: [ validarErrores, funcionesExcel, filtrosRolSucursal ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      grupoRolesPerfecto:[],
      search:'',
      gruposRoles: [],
      ciclos:[],
      ciclo:null,


      teacher: null,
      dialogReemplazo: false,
      teachers:[],
      teacherReemplazo: null,
      bloque: '',

      // Filtros avanzados
      niveles:[],
      nivel: null,

      planteles: [],
      plantel: null,

      horarios:[],
      horario: null,

      modalidades:[],
      modalidad:null,

      teachersFiltros:[],
			teacherFilter: null,

			dialogMaestro: false,

			escuela: 1,
			opAsignacion: null,

			asistencia: null,
			dialogAsistencia: false,
			grupo: null,
			codigo: null,
			dialogZoom: false,

			dialogMaps : {
        estatus:false
      },

			salones:[],

			preguntas:[],

			idrol_clases: null,

			mostrarBitacora: true,
			bitacora: null,
			teachersPosibles: [],

			grupoCompleto: null,

			rolReemplazo: [],

			dialogDobles: {
				estatus: false,
			},

		 	fecha_hoy: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    }),

    watch: {
      // Consultar cuando haya un cambio en el ciclo y este sea un ciclo seleccionado y no un ciclo vacio
      ciclo () {
        if(this.ciclo){
          this.consultar()
        }
      }
    },

    async created () {
      await this.initialize()
      await this.getTeachersActivos()
    	await this.getSalones()
    	await this.getPlanteles()
    	await this.getPreguntasBitacora()
    },

    computed:{

    	...mapGetters('login', ['getdatosUsuario','getEscuela'])

    },

    methods: {
      // Funcion para cargar datos iniciales
      initialize () {
      	this.cargar = true
        // Vaciar los ciclos
        this.ciclos = []
        // Obtener todos los ciclos
        this.$http.get('roles.ciclos.all').then(response=>{
          // hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
          // y solo queremos los ciclos que en su nombre dicen ciclo 
          for(const i in response.data){
            //  si es diferente a FE
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              // Y es igual a FE, se agrega a ciclos
              // por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }

      		this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.oficiales').then(response=>{
        	console.log( response.data )
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPreguntasBitacora () {
      	this.cargar = true
        this.preguntas = []
        return this.$http.get('bitacora.get.preguntas').then(response=>{
        	this.preguntas   = response.data

        	for( const i in this.preguntas ){ this.preguntas[i]['correcto'] = 0 }
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTeachersActivos( ){
      	this.cargar = true
      	this.teachers = []
        return this.$http.get('academico.teachers.activos').then(response=>{
        	console.log( response.data )
          this.teachers = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getSalones(){
    		this.cargar = true
        this.salones = []
        return this.$http.get('salones.list').then(response=>{
        	this.salones = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

      consultar () {
      	if( !this.ciclo ){
      		return this.validarErrorDirecto('Selecciona un ciclo por favor')
      	}
      	this.gruposRoles = []
      	this.cargar      = true
        this.$http.post('rol.clases', this.ciclo ).then(response=>{
          this.gruposRoles    = response.data.respuesta

          this.niveles        = response.data.respuesta.map((registro) => { return registro.id_nivel }).sort()
          
          this.horarios       = response.data.respuesta.map((registro) => { return registro.hora_inicio }).sort()

          this.rolReemplazo   = response.data.rolReemplazo

          // Obtener los nombres de tooodos los teachers
          let tlu1 = response.data.respuesta.filter( el => { return el.lu1_n }).map((registro) => { return registro.lu1_n })
          let tma1 = response.data.respuesta.filter( el => { return el.ma1_n }).map((registro) => { return registro.ma1_n })
          let tmi1 = response.data.respuesta.filter( el => { return el.mi1_n }).map((registro) => { return registro.mi1_n })
          let tju1 = response.data.respuesta.filter( el => { return el.ju1_n }).map((registro) => { return registro.ju1_n })
          let tvi1 = response.data.respuesta.filter( el => { return el.vi1_n }).map((registro) => { return registro.vi1_n })

          let tlu2 = response.data.respuesta.filter( el => { return el.lu2_n }).map((registro) => { return registro.lu2_n })
          let tma2 = response.data.respuesta.filter( el => { return el.ma2_n }).map((registro) => { return registro.ma2_n })
          let tmi2 = response.data.respuesta.filter( el => { return el.mi2_n }).map((registro) => { return registro.mi2_n })
          let tju2 = response.data.respuesta.filter( el => { return el.ju2_n }).map((registro) => { return registro.ju2_n })
          let tvi2 = response.data.respuesta.filter( el => { return el.vi2_n }).map((registro) => { return registro.vi2_n })

          let tlu3 = response.data.respuesta.filter( el => { return el.lu3_n }).map((registro) => { return registro.lu3_n })
          let tma3 = response.data.respuesta.filter( el => { return el.ma3_n }).map((registro) => { return registro.ma3_n })
          let tmi3 = response.data.respuesta.filter( el => { return el.mi3_n }).map((registro) => { return registro.mi3_n })
          let tju3 = response.data.respuesta.filter( el => { return el.ju3_n }).map((registro) => { return registro.ju3_n })
          let tvi3 = response.data.respuesta.filter( el => { return el.vi3_n }).map((registro) => { return registro.vi3_n })

          let tlu4 = response.data.respuesta.filter( el => { return el.lu4_n }).map((registro) => { return registro.lu4_n })
          let tma4 = response.data.respuesta.filter( el => { return el.ma4_n }).map((registro) => { return registro.ma4_n })
          let tmi4 = response.data.respuesta.filter( el => { return el.mi4_n }).map((registro) => { return registro.mi4_n })
          let tju4 = response.data.respuesta.filter( el => { return el.ju4_n }).map((registro) => { return registro.ju4_n })
          let tvi4 = response.data.respuesta.filter( el => { return el.vi4_n }).map((registro) => { return registro.vi4_n })

          this.teachersFiltros = tlu1.concat(tma1).concat(tmi1).concat(tju1).concat(tvi1).concat(tlu2).concat(tma2).concat(tmi2).concat(tju2).concat(tvi2).concat(tlu3).concat(tma3).concat(tmi3).concat(tju3).concat(tvi3).concat(tlu4).concat(tma4).concat(tmi4).concat(tju4).concat(tvi4).sort()

          // this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      cargarBloque(){
      	
      	if( !this.ciclo ){
      		return this.validarErrorDirecto('Selecciona un ciclo por favor')
      	}

      	this.cargar      = true
      
        this.$http.post('rol.clases.add', this.gruposRoles ).then(response=>{
          this.consultar()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      
      },

      async guardarAsistencia( ){

      	const id_reemplazo = 0
      	if( this.teacherReemplazo ){
      		console.log( this.teacherReemplazo )
      		const reemplazo = this.teachers.find( el => el.nombre_completo == this.teacherReemplazo )
      		if( reemplazo ){ this.id_reemplazo = reemplazo.id_usuario }

      		// Aquí hay que poner la funciona para guardar el reemplazo
      		await this.guardarMaestro( reemplazo )
      	}else{
      		this.saveAsistencia() 
      	}
      },

      saveAsistencia( ){
      	const id_reemplazo = 0

      	if( this.teacherReemplazo ){
      		console.log( this.teacherReemplazo )
      		const reemplazo = this.teachers.find( el => el.nombre_completo == this.teacherReemplazo )
      		if( reemplazo ){ this.id_reemplazo = reemplazo.id_usuario }
      	}

      	const payload = {
					bloque:       this.bloque,
					id_teacher:   this.asistencia,
					asistencia:   this.opAsignacion,
					grupo:        this.grupo,
					reemplazo:    this.teacherReemplazo,
					idrol_clases: this.idrol_clases,
					id_usuario:   this.getdatosUsuario.iderp,
					preguntas:    this.preguntas,
					id_reemplazo
      	}

      	if( !this.opAsignacion ){
      		return this.validarErrorDirecto('Favor de llenar correctamente los datos')
      	}

      	this.cargar      = true
      
        this.$http.post(`rol.asistencia`, payload).then(response=>{
        	this.bloque           = '' 
	      	this.grupo            = null
	      	this.asistencia       = null
	      	this.dialogAsistencia = false
	      	this.teacherReemplazo = ''
	      	for( const i in this.preguntas ){ this.preguntas[i]['correcto'] = 0 }

        	this.validarSuccess( 'se actualizó el teacher' )
          this.consultar()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      	this.cargar      = false
      },

      guardarMaestro( reemplazo ){

      	console.log( reemplazo )

      	let bloque1 = ["lu1","ma1","mi1", "lu2","ma2","mi2","lu3","ma3","mi3","lu4","ma4","mi4"]

      	let bloque2 = ["ju1","vi1","ju2","vi2","ju3","vi3","ju4","vi4"]

      	let diasSemana = ['lu', 'ma', 'mi'];

      	diasSemana = ( diasSemana.some(dia => this.bloque.includes(dia)) ) ? bloque1 : bloque2 

				const indiceSeleccionado = diasSemana.indexOf(this.bloque);

				let bloques = indiceSeleccionado !== -1 ? diasSemana.slice(indiceSeleccionado) : []
				let bloquesfill = indiceSeleccionado !== -1 ? diasSemana.slice(indiceSeleccionado) : []

      	this.cargar      = true
      
      	// Validar que teacher es....
        let numero_teacher = 0

        // Si el teacher que se modifico, fue de estos bloques... es teacher uno
        if( ['lu1','ma1','mi1','lu2','ma2','mi2','lu3','ma3','mi3','lu4','ma4','mi4'].includes( this.bloque ) ){
       		numero_teacher = 1
        }else{ 
        	numero_teacher = 2 // Si no está, es por que es teacher 2
        }

        let payload = {
          id_maestro     : reemplazo.id_trabajador,
          id_usuario     : reemplazo.id_usuario,
          id_reemplazo   : reemplazo.id_trabajador,
          id_grupo       : this.grupo, // ===== idgrupo
          activo_sn      : 1,
          numero_teacher,
          escuela        : this.grupoCompleto.id_unidad_negocio,
          ciclo          : this.ciclo,
          opAsignacion   : 2,
          bloque         : this.bloque,
          bloques,
          arregloTeacher : bloquesfill.fill(this.opSinTeacher ? 0 : reemplazo.id_usuario),
          actualizo      : this.getdatosUsuario.iderp
        }


        const { lunes, martes, miercoles, jueves, viernes, sabado, domingo } = this.grupoCompleto


      	if( lunes ){
        	
        	let { hora_fin, hora_inicio, iderp } = this.grupoCompleto

      		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == reemplazo.id_usuario && el.lunes == lunes && el.deleted == 0 && el.iderp != iderp }) 

      		// sacar las horas en las que el grupo se da clase
        	for( const i in gruposTeacher ){
        
	        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

	        	if( hora_inicio >= this.grupoCompleto.hora_inicio &&  hora_inicio < this.grupoCompleto.hora_fin || hora_fin > this.grupoCompleto.hora_inicio &&  hora_fin <= this.grupoCompleto.hora_fin ){
	        		this.validarErrorDirecto('Se empalma hora y día')
	        		return
	        	}

	        }
      	}


      	if( martes ){
        	
        	let { hora_fin, hora_inicio, iderp } = this.grupoCompleto

      		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == reemplazo.id_usuario && el.martes == martes && el.deleted == 0 && el.iderp != iderp }) 

      		// sacar las horas en las que el grupo se da clase
        	for( const i in gruposTeacher ){
        
	        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

	        	if( hora_inicio >= this.grupoCompleto.hora_inicio &&  hora_inicio < this.grupoCompleto.hora_fin || hora_fin > this.grupoCompleto.hora_inicio &&  hora_fin <= this.grupoCompleto.hora_fin ){
	        		this.validarErrorDirecto('Se empalma hora y día')
	        		return
	        	}

	        }
      	}


      	if( miercoles ){
        	
        	let { hora_fin, hora_inicio, iderp } = this.grupoCompleto

      		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == reemplazo.id_usuario && el.miercoles == miercoles && el.deleted == 0 && el.iderp != iderp }) 

      		// sacar las horas en las que el grupo se da clase
        	for( const i in gruposTeacher ){
        
	        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

	        	if( hora_inicio >= this.grupoCompleto.hora_inicio &&  hora_inicio < this.grupoCompleto.hora_fin || hora_fin > this.grupoCompleto.hora_inicio &&  hora_fin <= this.grupoCompleto.hora_fin ){
	        		this.validarErrorDirecto('Se empalma hora y día')
	        		return
	        	}

	        }
      	}

      	if( jueves ){
        	
        	let { hora_fin, hora_inicio, iderp } = this.grupoCompleto

      		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == reemplazo.id_usuario && el.jueves == jueves && el.deleted == 0 && el.iderp != iderp }) 

      		// sacar las horas en las que el grupo se da clase
        	for( const i in gruposTeacher ){
        
	        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

	        	if( hora_inicio >= this.grupoCompleto.hora_inicio &&  hora_inicio < this.grupoCompleto.hora_fin || hora_fin > this.grupoCompleto.hora_inicio &&  hora_fin <= this.grupoCompleto.hora_fin ){
	        		this.validarErrorDirecto('Se empalma hora y día')
	        		return
	        	}

	        }
      	}


      	if( viernes ){
        	
        	let { hora_fin, hora_inicio, iderp } = this.grupoCompleto

      		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == reemplazo.id_usuario && el.viernes == viernes && el.deleted == 0 && el.iderp != iderp }) 

      		// sacar las horas en las que el grupo se da clase
        	for( const i in gruposTeacher ){
        
	        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

	        	if( hora_inicio >= this.grupoCompleto.hora_inicio &&  hora_inicio < this.grupoCompleto.hora_fin || hora_fin > this.grupoCompleto.hora_inicio &&  hora_fin <= this.grupoCompleto.hora_fin ){
	        		this.validarErrorDirecto('Se empalma hora y día')
	        		return
	        	}

	        }
      	}

      	if( sabado ){
        	
        	let { hora_fin, hora_inicio, iderp } = this.grupoCompleto

      		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == reemplazo.id_usuario && el.sabado == sabado && el.deleted == 0 && el.iderp != iderp }) 

      		// sacar las horas en las que el grupo se da clase
        	for( const i in gruposTeacher ){
        
	        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

	        	if( hora_inicio >= this.grupoCompleto.hora_inicio &&  hora_inicio < this.grupoCompleto.hora_fin || hora_fin > this.grupoCompleto.hora_inicio &&  hora_fin <= this.grupoCompleto.hora_fin ){
	        		this.validarErrorDirecto('Se empalma hora y día')
	        		return
	        	}

	        }
      	}

        payload[`${this.bloque}`] = reemplazo

        return this.$http.post(`academico.add.grupoteacher.recep`, payload).then(response=>{
        	this.saveAsistencia() 
        	this.validarSuccess( 'Actualización correcta de datos' )

        }).catch( error =>{
        	console.log('ERROR DE NO sé donde',error)
          this.validarError( 'Error al actualizar', error  )
        }).finally( () => { this.cargar = false })

      	this.cargar      = false

      },

      getBitacora( ){

      	this.mostrarBitacora = true 

      	const payload = {
					bloque: this.bloque, 
					id_teacher: this.asistencia, 
					idrol_clases: this.idrol_clases
      	}

      	this.cargar      = true
      
        this.$http.post(`bitacora.get`, payload).then(response=>{
        	this.preguntas = response.data.preguntas

        	this.bitacora  = response.data.bitacora
        }).catch( error =>{
        	this.mostrarBitacora = false
        	this.getPreguntasBitacora()
        }).finally( () => { this.cargar = false })

      	this.cargar      = false
      },

      exportar( ){
      	this.exportExcel( this.filterGrupos  , 'ROL_CLASES')
      },

      copiarPortapapeles ( item ) {

      	// Expresión regular para encontrar enlaces en el texto
				var expresionRegular = /https?:\/\/[^\s]+/g;

				// Usar el método match() para obtener todos los enlaces en un arreglo
				var enlaces = item.match(expresionRegular);

        navigator.clipboard.writeText( enlaces[0] ).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      },

      abrirZoom( item ){

				// Expresión regular para encontrar enlaces en el texto
				var expresionRegular = /https?:\/\/[^\s]+/g;

				// Usar el método match() para obtener todos los enlaces en un arreglo
				var enlaces = item.match(expresionRegular)[0];

				window.open(enlaces, "_blank");
      },

      saveSalon( value ){
      	const payload = {
      		idrol_clases: value.idrol_clases,
      		id_salon    : value.id_salon_2,
      	}

      	this.cargar  = true

        return this.$http.post('roles.salon.grupo', payload).then((response) => {

          this.consultar()
          this.cargar    = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },	

      getPosiblesMaestros( value, grupo, bloqueo ){

      	if( bloqueo ){ return }
      	// VALIDAR QUE SE PUEDA LLENAR LA BITACORA Y LA ASISTENCIA

      	// // VALIDAR PRIMER SI ES ENTRE SEMANA
      	// if( !grupo.sabado && !grupo.domingo ){

      	// 	console.log( grupo[`${this.bloque}_fecha`] )

      	// 	// Validamos la fecha, si la fecha de hoy es diferente, no se puede llenar bitacora
      	// 	if( grupo[`${this.bloque}_fecha`] != (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) ){
      	// 		return this.validarErrorDirecto('No puedes llenar la asistencia ni la bitacora por que no corresponde al día')
      	// 	}else{

      	// 		// Si es mismo día, solo hay que validar la hora
				// 		const fechaActual = new Date();

				// 		// Obtener la hora actual
				// 		const horas = fechaActual.getHours();
				// 		const minutos = fechaActual.getMinutes();

				// 		// Formatear la hora como una cadena
				// 		const horaActual = `${horas}:${minutos}`;

      	// 		if( grupo.hora_fin > horaActual ){
      	// 			return this.validarErrorDirecto('No puedes llenar la asistencia ni la bitacora ya que aún no termina la clase')
      	// 		}
      	// 	}
      	// }


      	this.grupoCompleto = grupo
      	const payload = {
      		...grupo,
      		dia: value
      	}

      	this.cargar  = true

        this.$http.post('roles.posibles.teachers', payload).then((response) => {

        	if( grupo.grupo.match('ONLINE') ){
        		this.teachersPosibles = this.teachers
        	}else{
        		this.teachersPosibles = response.data
        	}

          this.dialogAsistencia = true
          this.cargar    = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      }
    },
  }

</script>
<style scoped>
  .v-data-table__wrapper{

  }

  .v-data-table__wrapper::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  .v-data-table__wrapper::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  #yellow{
    background-color: yellow !important;
  }


</style>
